<template>
  <b-container fluid>
    <bo-page-title />

    <b-card v-if="isList" no-body>
      <b-card-header>
        <b-row>
          <b-col lg="5">
            <h5 class="card-title">{{ pageTitle }} List </h5>
          </b-col>
          <b-col lg="3">
            <b-form-group>
              <v-select placeholder="Select Level" @input="doFill" v-model="filter.level" :options="mrLevel" label="label" :clearable="true" :reduce="v=>v.value"></v-select>
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <SearchInput :value.sync="filter.search" @search="doFill" />
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body class="p-0">
        <b-table
          :fields="fields"
          :items="dataList||[]"
          :per-page="perPage"
          :primary-key="idKey"
          :busy="!dataList"
          responsive
          show-empty
          striped
        >
          <template #empty>
            <div class="text-center">
              <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
              <h4 align="center"><span v-if="Object.keys($route.query).length">No search results found</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
            </div>
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle mr-3 text-info"></b-spinner>
              <strong class="text-info">Loading...</strong>
            </div>
          </template>
          <template #cell(number)="v">
            {{(data.per_page*(data.current_page-1))+v.index+1}}
          </template>
          <template
            #cell(bu_is_active) = "data"
          >
            <b-badge
              v-if="data.value=='Y'"
              variant="success"
            >Active</b-badge>
            <b-badge
              v-else
              variant="danger"
            >Inactive</b-badge>
          </template>
          <template
            #cell(action)="data"
          >
            <b-button
              v-if="moduleRole('edit')"

              v-b-tooltip.hover
              title="Edit"
              class="btn-icon"
              variant="outline-primary"
              size="sm"
              :to="{name: $route.name, params: {pageSlug: data.item[idKey]}}"
            >
              <i class="ti-marker-alt"></i>
            </b-button>
            <b-button
              v-if="moduleRole('changestatus') && data.item.bu_is_locked=='N'"

              v-b-tooltip.hover
              title="Change Status"
              class="btn-icon"
              variant="outline-info"
              size="sm"
              @click="doChangeStatus(data.index, data.item)"
            >
              <i class="ti-settings"></i>
            </b-button>
            <b-button
              v-if="moduleRole('delete') && data.item.bu_is_locked=='N'"

              v-b-tooltip.hover
              title="Delete"
              class="btn-icon"
              variant="outline-danger"
              size="sm"
              @click="doDelete(data.index, data.item)"
            >
              <i class="ti-trash"></i>
            </b-button>

            <span v-if="!(moduleRole('delete') || moduleRole('changestatus') || moduleRole('edit'))">-</span>
          </template>
        </b-table>
      </b-card-body>
      <b-card-footer>
        <template v-if="(dataList||[]).length&&data.total>10" #footer>
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <label for="perPage" class="mb-0 mr-2">Show</label>
                <b-form-select
                  size="sm"
                  id="perPage"
                  class="w-auto mx-50"
                  v-model="perPage"
                  :options="Config.dataShownOptions"
                />
              <label for="perPage" class="mb-0 ml-2">items per page</label>
            </div>
            <b-pagination
              class="mb-0"
              v-model="pageNo"
              :per-page="data.per_page"
              :total-rows="data.total"
            />
          </div>
        </template>
      </b-card-footer>
    </b-card>

    <template v-else>
      <Form :row.sync="row" v-bind="passToSub" />
    </template>
  </b-container>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Form from './Form.vue'
const _ = global._

export default {
  extends: GlobalVue,
  components:{Form},
  data() {
    return {
      idKey:'bu_id',
      statusKey:'bu_is_active',
      mrLevel:[],
      fields: [
        {
          key: 'number',
          label: '#',
        },
        {
          key: 'bu_full_name',
          label: 'Full Name',
        },
        
        {
          key: 'bu_username', 
          label: 'Username'
        },
        {
          key: 'bul_name',
          label: 'Role',
        },
        {
          key: 'bu_is_active',
          label: 'Status',
        },
        {
          key: 'action',
        },
      ],
    }
  },
  computed:{
    passToSub(){
      return _.assign(this.passToSubComp||{}, {
        mrLevel:this.mrLevel
      })
    }
  },
  mounted(){
    this.apiGet()
  },
  methods: {
    doFill(){
      this.doFilter()
    }
  },
  watch:{
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}
</script>