<template>
<b-card no-body>
  <validation-observer
    v-slot="{ handleSubmit }" ref="VForm"
  >
    <b-form @submit.prevent="handleSubmit(doSubmitUser())">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Add':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="8">
            <b-row>
              <b-col lg="6">
                <b-form-group>
                  <label>Full Name <span class="text-danger mr5">*</span></label>
                  <b-form-input v-model="row.bu_full_name" placeholder="e.g Full Name">
                  </b-form-input>
                  <VValidate 
                    name="Full Name" 
                    v-model="row.bu_full_name" 
                    :rules="mrValidation.bu_full_name" 
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group>
                  <label>Email Address </label>
                  <b-form-input v-model="row.bu_email_address" placeholder="e.g Email Address"></b-form-input>
                  <VValidate 
                    name="Email" 
                    v-model="row.bu_email_address" 
                    :rules="mrValidation.bu_email_address" 
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group>
                  <label>Mobile Number</label>
                  <b-form-input v-model="row.bu_phone" placeholder="e.g Mobile Number" :formatter="e => String(e).substring(0, 13)" ></b-form-input>
                  <VValidate 
                    name="Mobile Number" 
                    v-model="row.bu_phone" 
                    :rules="{regex:/^(\+\d{1,3}[- ]?)?\d{10,13}$/}" 
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group>
                  <label>Username <span class="text-danger mr5">*</span></label>
                  <b-form-input v-model="row.bu_username" placeholder="e.g Username">
                  </b-form-input>
                  <VValidate 
                    name="Username" 
                    v-model="row.bu_username" 
                    :rules="mrValidation.bu_username" 
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group class="mb-3">
                  <label>Password <span v-if="!row.bu_id" class="text-danger mr5">*</span></label>
                  <b-form-input type="password" v-model="row.bu_password" placeholder="Password">
                  </b-form-input>
                  <VValidate 
                    name="Password" 
                    v-model="row.bu_password" 
                    :rules="{required:!row.bu_id, min:5}"
                  />
                </b-form-group>
              </b-col>
              <b-col v-if="!isAccountSetting" lg="6">
                <b-form-group>
                  <label>Level <span class="text-danger mr5">*</span></label>
                  <v-select placeholder="Select Level" v-model="row.bu_level" :options="$parent.mrLevel" label="label" :reduce="v=>v.value"></v-select>
                  <VValidate 
                    name="User Role" 
                    v-model="row.bu_level" 
                    :rules="mrValidation.bu_level" 
                  />
                </b-form-group>
              </b-col>
              <b-col v-if="!isAccountSetting && row.bu_is_locked=='N'" lg="6">
                <b-form-group class="mt-3">
                  <label>Status <span class="text-danger mr5">*</span></label>
                  <b-form-radio-group
                    :options="Config.mr.StatusOptions"
                    v-model="row[$parent.statusKey]"
                  />
                  <!-- <b-form-radio-group v-model="selected" :options="options" class="mb-3" value-field="item"
                    text-field="name" disabled-field="notEnabled"></b-form-radio-group> -->
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="4">
            <div class="file-uploader">
              <label>Avatar</label>
              <Uploader v-model="row.bu_pic" type="user"/>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Cancel</button>
            <b-button type="submit" variant="info" class="btn-rounded">Save Changes</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>
  </validation-observer>
</b-card>
</template>

<script>
import $ from 'jquery'
const _ = global._

export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object,
    mrLevel:Array,
    isAccountSetting:{
      type:Boolean,
      default:false
    }
  },
  methods:{
    doSubmitUser(){
      return this.$parent.doSubmit(
          "/do/Users",
          _.assign({type:this.$parent.isAdd?'add':'update'}, {id: (this.$parent.pageId||this.$parent.pageSlug)}, _.clone(this.row)), 
          (type, resp)=>{
            if(type=='success'){
              if(this.isAccountSetting){
                location.reload()
              }else{
                this.$router.push({name:this.$route.name})
              }
            }else{
              if(resp.response.status==422) {
                if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                  var msg = ""
                  $.each(Object.keys(resp.response.data),(i,v)=>{
                    msg = msg+" <br/>"+v+" : "+_.values(resp.response.data[v])
                  })
                  return this.$swal("Blocked With Validation!", msg)
                }else{
                  return this.$swal("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              }else if(resp.response.status==400){
                return this.$swal({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', 'VForm', this.$refs
      )
    }
  }
}
</script>